import moment from 'moment'

export const loadSnackbar = (
  message = "Something went wrong!",
  type = "error"
) => ({
	type: 'LOAD_SNACKBAR',
	payload: {
		message,
		type,
		open: true
	}
})

export const lgvHealthUseCaseRoutesCode = ['lgv-nav', 'lgv-ish', 'lgv-hsh']

export const lgvHealthUseCaseList = [
	'LGV Navigation Quality',
	'LGV Hydraulic System Health',
	'LGV Inverter System Health'
]

export const generateRanges = (plantTimezone: string) => [
	{
		label: 'Last 12 hours',
		value: [
			moment.utc().subtract(12, 'hours').format('YYYY-MM-DDTHH:mm:ss.000'),
			moment.utc().format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '12', unit: 'hours' }
	},
	{
		label: 'Last 24 hours',
		value: [
			moment.utc().subtract(24, 'hours').format('YYYY-MM-DDTHH:mm:ss.000'),
			moment.utc().format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '24', unit: 'hours' }
	},

	{
		label: 'Last 2 days',
		value: [
			moment.utc().subtract(48, 'hours').format('YYYY-MM-DDTHH:mm:ss.000'),
			moment.utc().format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '48', unit: 'hours' }
	},
	{
		label: 'Last 7 days',
		value: [
			moment
				.utc()
				.startOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.subtract(6, 'days')
				.format('YYYY-MM-DDTHH:mm:ss.000'),
			moment
				.utc()
				.endOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '7', unit: 'days' }
	},
	{
		label: 'Last 2 weeks',
		value: [
			moment
				.utc()
				.startOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.subtract(13, 'days')
				.format('YYYY-MM-DDTHH:mm:ss.000'),
			moment
				.utc()
				.endOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '14', unit: 'days' }
	},
	{
		label: 'Last 30 days',
		value: [
			moment
				.utc()
				.startOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.subtract(29, 'days')
				.format('YYYY-MM-DDTHH:mm:ss.000'),
			moment
				.utc()
				.endOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '30', unit: 'days' }
	},
	{
		label: 'Last 90 days',
		value: [
			moment
				.utc()
				.startOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.subtract(89, 'days')
				.format('YYYY-MM-DDTHH:mm:ss.000'),
			moment
				.utc()
				.endOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '90', unit: 'days' }
	},
	{
		label: 'Last 6 months',
		value: [
			moment
				.utc()
				.startOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.subtract(6, 'months')
				.format('YYYY-MM-DDTHH:mm:ss.000'),
			moment
				.utc()
				.endOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '6', unit: 'months' }
	},
	{
		label: 'Last 1 year',
		value: [
			moment
				.utc()
				.startOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.subtract(1, 'years')
				.format('YYYY-MM-DDTHH:mm:ss.000'),
			moment
				.utc()
				.endOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.format('YYYY-MM-DDTHH:mm:ss.000')
		],
		difference: { value: '1', unit: 'years' },

		placement: 'left'
	},
	{
		label: 'Last 2 years',
		value: [
			moment
				.utc()
				.startOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.subtract(2, 'years')
				.format('YYYY-MM-DDTHH:mm:ss.000'),
			moment
				.utc()
				.endOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '2', unit: 'years' }
	},
	{
		label: 'Last 3 years',
		value: [
			moment
				.utc()
				.startOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.subtract(3, 'years')
				.format('YYYY-MM-DDTHH:mm:ss.000'),
			moment
				.utc()
				.endOf('day')
				.subtract(moment().tz(plantTimezone)?.utcOffset(), 'minutes')
				.format('YYYY-MM-DDTHH:mm:ss.000')
		],
		placement: 'left',
		difference: { value: '3', unit: 'years' }
	}
]

// Round up to the next 5-minute mark
export const roundUpToNext5Minutes = (date: any) => {
	const ms = date.getTime()
	const roundedMs = Math.ceil(ms / (5 * 60 * 1000)) * (5 * 60 * 1000)
	return new Date(roundedMs)
}

// Round up to the next 1-hour mark
export const roundUpToNext1Hour = (date: any) => {
	const ms = date.getTime()
	const roundedMs = Math.ceil(ms / (60 * 60 * 1000)) * (60 * 60 * 1000)
	return new Date(roundedMs)
}

// Round up to the next 1-day mark
export const roundUpToNext1Day = (date: any) => {
	const ms = date.getTime()
	const roundedMs =
		Math.ceil(ms / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000)
	return new Date(roundedMs)
}

export const getFullRangeCategories = (
	timeRange: any,
	cubeMatchingString: string
): Set<string> => {
	const categories: any = new Set()

	const aggregationValues: any = {
		FiveMinAgg: {
			interval: 5 * 60 * 1000, // 5 minutes in milliseconds
			roundFunction: roundUpToNext5Minutes
		},
		HourlyAgg: {
			interval: 60 * 60 * 1000, // 1 hours in milliseconds
			roundFunction: roundUpToNext1Hour
		},
		DailyAgg: {
			interval: 24 * 60 * 60 * 1000, // 1 day minutes in milliseconds
			roundFunction: roundUpToNext1Day
		},
		minute: {
			interval: 5 * 60 * 1000, // 5 minutes in milliseconds
			roundFunction: roundUpToNext5Minutes
		},
		hour: {
			interval: 60 * 60 * 1000, // 1 hours in milliseconds
			roundFunction: roundUpToNext1Hour
		},
		day: {
			interval: 24 * 60 * 60 * 1000, // 1 day minutes in milliseconds
			roundFunction: roundUpToNext1Day
		}
	}

	const startDateTime = new Date(timeRange[0] + 'Z')
	const endDateTime = new Date(timeRange[1] + 'Z')
	const aggregation = aggregationValues[cubeMatchingString]

	if (!aggregation) {
		throw new Error(`Cube Aggregation: ${cubeMatchingString} not supported`)
	}

	const startTime = aggregation.roundFunction(startDateTime)?.getTime()
	const endTime = endDateTime?.getTime()

	const interval = aggregation.interval

	if (endTime && startTime) {
		for (let time = startTime; time <= endTime; time += interval) {
			categories.add(new Date(time).toISOString().slice(0, -1))
		}
	}

	return categories
}
