/* eslint-disable no-console,func-names,react/no-multi-comp */
import React, { useEffect, useState } from "react";
import { clone, cloneDeep, get, set, isEmpty } from "lodash";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
const ProcessRoomTags = (props: any) => {
  const { items, onSeqChange, onError, resetListItems } = props;
  const [selectedStatus, selectStatus] = useState("");
  const [CCPsMonitoredError, setCCPsMonitoredError] = useState<any>({});
  const [StatusError, setStatusError] = useState<any>({});
  const [showNewField, setShowNewField] = useState<boolean>(false);
  const [newFieldValue, setNewFieldValue] = useState<any>({});
  const [statusOptions, setStatusOptions] = useState<any>(null);
  const [currentPlantData, setCurrentPlantData] = useState<any>(items);

  const dummyConfiguration = {
    masterTag: [],
    assetTags: [],
  };

  useEffect(() => {
    let options: any;
    if (items === null || items?.length === 0 || items === undefined) {
      options = dummyConfiguration ? Object.keys(dummyConfiguration) : [];
      setCurrentPlantData(dummyConfiguration);
    } else {
      options = currentPlantData ? Object.keys(currentPlantData) : [];
      setCurrentPlantData(items);
    }
    setStatusOptions(options);
  }, [items]);

  const checkErrorUnderCurrentSelection = () => {
    let isErrorPresent = false;
    if (selectedStatus === "masterTag" || selectedStatus === "assetTags") {
      Object.keys(StatusError).forEach((indexKey) => {
        Object.keys(StatusError[indexKey]).forEach((key) => {
          if (isEmpty(StatusError[indexKey][key])) isErrorPresent = true;
        });
      });
    }
    return isErrorPresent;
  };

  const handleItemValueChange = (
    data: any,
    editIndex: number,
    updatedKey: string = ""
  ) => {
    let clonedUpdatedItems = cloneDeep(currentPlantData);

    const statusCondition =
      selectedStatus === "masterTag" || selectedStatus === "assetTags";

    const valueCondition = updatedKey === "value" && isEmpty(data[updatedKey]);

    if (statusCondition && valueCondition) {
      if (valueCondition) {
        const clonedError = clone(StatusError);
        setStatusError({
          ...clonedError,
          [editIndex]: {
            ...clonedError[editIndex],
            value: "Invalid value",
          },
        });

        onError(true);
      }
    } else {
      set(
        clonedUpdatedItems,
        `${selectedStatus}`,
        get(clonedUpdatedItems, `${selectedStatus}`, []).map(
          (item: any, index: number) => {
            if (editIndex === index) {
              return data;
            }
            return item;
          }
        )
      );
      const clonedCCPError = clone(CCPsMonitoredError);

      setCCPsMonitoredError({
        ...clonedCCPError,
        [data.asset]: "",
      });
      const clonedStatusError = clone(StatusError);
      delete clonedStatusError[editIndex];

      setStatusError({
        ...clonedStatusError,
      });

      const isErrorPresent = checkErrorUnderCurrentSelection();

      onSeqChange(clonedUpdatedItems, !isErrorPresent);
    }
  };

  const handleNewFieldCheck = () => {
    const updatedFieldData = cloneDeep(newFieldValue);
    const updatedError = { key: "", tag: "" };

    const valueCondition =
      isEmpty(updatedFieldData) || newFieldValue === JSON.stringify(0);

    if (valueCondition) {
      onError(true);
      set(updatedError, `value`, "Invalid value");
    }

    if (!isEmpty(newFieldValue)) {
      const updatedData = cloneDeep(currentPlantData);
      onError(false);
      setNewFieldValue({});
      if (
        selectedStatus !== "" &&
        newFieldValue !== "" &&
        newFieldValue !== null &&
        newFieldValue !== undefined
      ) {
        updatedData[selectedStatus].push(newFieldValue);
      }
      onSeqChange(updatedData);
      setShowNewField(false);
    }
  };

  return currentPlantData ? (
    <div className="selfConfigComponent" style={{ width: "470px" }}>
      <FormControl
        variant="outlined"
        fullWidth
        size="medium"
        sx={{ marginTop: "16px" }}
      >
        <InputLabel id="demo-multiple-name-label">Select Status</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={selectedStatus}
          onChange={(event: any) => {
            selectStatus(event.target.value);
            resetListItems();
          }}
          input={<OutlinedInput label="Select Status" />}
        >
          {statusOptions?.map((option: string, index: number) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedStatus === "masterTag" || selectedStatus === "assetTags" ? (
        <>
          {get(currentPlantData, `${selectedStatus}`, [])?.map(
            (item: any, index: number) => (
              <React.Fragment key={`${index}-${item.asset}`}>
                <TextField
                  label={index + 1}
                  value={item}
                  type="text"
                  fullWidth
                  onChange={(event: any) => {
                    handleItemValueChange(event.target.value, index);
                  }}
                  sx={{
                    marginTop: "16px",
                  }}
                  error={!!CCPsMonitoredError[item.asset]}
                  helperText={
                    !!CCPsMonitoredError[item.asset]
                      ? CCPsMonitoredError[item.asset]
                      : ""
                  }
                />
              </React.Fragment>
            )
          )}
          {showNewField ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                paddingTop: "8px",
              }}
            >
              <Grid style={{ padding: "8px 16px 0 0" }} item xs={4}>
                <TextField
                  label="Value"
                  type="text"
                  fullWidth
                  sx={{
                    marginTop: "16px",
                  }}
                  onChange={(event) => setNewFieldValue(event.target.value)}
                />
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CheckIcon onClick={() => handleNewFieldCheck()} />
                <CloseIcon
                  onClick={() => {
                    setNewFieldValue({});
                    setShowNewField(false);
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setShowNewField(true);
                }}
              >
                Add New
              </Button>
            </div>
          )}
        </>
      ) : null}
    </div>
  ) : (
    <div
      style={{
        padding: "25px 5px 10px",
        height: "24px",
        width: "auto",
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
      }}
    >
      Data not found
    </div>
  );
};

export default ProcessRoomTags;
