import { CubejsApi, DeeplyReadonly, Query, QueryRecordType, ResultSet } from '@cubejs-client/core';
import Axios from '../AxiosConfig/axiosConfigSAT';
import { hasInvalidCubePayload } from './cubeUtil';

/**
* This function makes a Cube API call and tracks the call duration along with the success/error response.
*
* @param cubeJsApi - The CubejsApi instance.
* @param query - The query to be executed.
* @param isMultiQuery - (Optional) A flag to determine whether it's a multi-query request.
* @returns A Promise with the result of the query, including success/error flags and the data.
*/
interface FetchAndTrackResponse {
 isSuccess: boolean;
 isError: boolean;
 data: any;
}
const fetchAndTrackCubeQuery = async (
 cubeJsApi: CubejsApi,
 query: DeeplyReadonly<Query | Query[]>,
 isMultiQuery = false
): Promise<FetchAndTrackResponse> => {
  // cube query validation
  if(hasInvalidCubePayload(query)){
    return {
      isSuccess: false,
      isError: true,
      data: []
    }
  }
  // Logger Enabled Functionality
  const loggerSeed = localStorage.getItem("loggerSeed") && JSON.parse(localStorage.getItem("loggerSeed") as any);
  const isMetricEnabled = loggerSeed && loggerSeed.find((val:any) => val["key"] === "cubeMetric")?.value === 'true'
  const isEventEnabled = loggerSeed && loggerSeed.find((val:any) => val["key"] === "cubeEvent")?.value === 'true'

  const startTime = Date.now();
  try {
      const resultSet: ResultSet<QueryRecordType<DeeplyReadonly<Query | Query[]>>> = await cubeJsApi.load(query);
      const endTime = Date.now();
      const duration = endTime - startTime;

      // Success Metric Callback
      if(isMetricEnabled){
        const metricPayload = {
          type:"Metric",
          payload:{
            name:"useCubeQuery Response Time",
            duration
          }
        }
        await Axios.post('azureinsightslogger', metricPayload)
      }

      // Success Event Callback
      if(isEventEnabled){
        const eventPayload = {
            type:"Event",
            payload:{
              name:"useCubeQuery Success",
              url:window.location.href,
              query,
              resultSet
            }
          }
        await Axios.post('azureinsightslogger', eventPayload);
      }

      return {
        isSuccess: true,
        isError: false,
        data: isMultiQuery
          ? resultSet
          : resultSet.serialize().loadResponse.results.map((ele) => ele.data),
      };
    } catch (error) {
      const endTime = Date.now();
      const duration = endTime - startTime;

      // Error Metric Callback
      if(isMetricEnabled){
        const metricPayload = {
          type:"Metric",
          payload:{
            name:"useCubeQuery Response Time",
            duration
          }
        } 
        await Axios.post('azureinsightslogger', metricPayload)
      }
      
      // Error Event Callback
      if(isEventEnabled){
        const eventPayload = {
          type:"Event",
          payload:{
            name:"useCubeQuery Error",
            url:window.location.href,
            query,
            error
          }
        }
        await Axios.post('azureinsightslogger', eventPayload)
      }

      return {
        isSuccess: false,
        isError: true,
        data: error instanceof Error ? error.message: 'Unknown cube error',
      };
    }
};

export default fetchAndTrackCubeQuery;
