import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import store from "./Redux/store";
import { SidebarProvider } from "./contexts/SidebarContext";
import { msConfig } from "./Services/authService";
import { registerLicense } from "@syncfusion/ej2-base";
import AuthAD from "./Auth";
import OEMSignOffPage from "./pages/master/UsecaseDashboard/SATSignOff/OEMDocUploadPage";
import "./index.css";
import App from "./App";
import { env } from "./env";

registerLicense(env.eam_syncfusion_license);

// const DEBUG = env.environment === "DEV";

// if (!DEBUG) {
//   devtool.disableConsoleLogs();
//   devtool.disableContextMenu();
//   devtool.disableHotKeys();
//   devtool.legalDevtoolWarning();
//   // devtool.disable40XErrorConsoleLogs();
// }

const url = window.location.pathname;

const qc = new QueryClient();

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          {url.includes("sat/upload-docs/") ? (
            <OEMSignOffPage />
          ) : (
            <MsalProvider instance={msConfig}>
              <AuthAD>
                <QueryClientProvider client={qc}>
                  <App />
                </QueryClientProvider>
              </AuthAD>
            </MsalProvider>
          )}
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);
